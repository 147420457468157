import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import Header from "../header"
import Footer from "../footer"
import BookNowBanner from "../home/book"
import StayHere from "../bedroom/stayHere"
import TellUs from "../TellUs"
import PopupMessage from "../popupMessage"
//import PopupImage from "../popup/";

import { Main } from "../atoms"

import Hero from "../home/hero"
import { handleGit } from "../../utils/handleGit"
import FadeInWrapper from "../fadeInWrapper"

const Layout = ({
    bookBannerData = undefined,
    stayHereData = undefined,
    tellUsData = undefined,
    heroData = undefined,
    navigationPreviewData = undefined,
    footerPreviewData = undefined,
    popupPreviewData = undefined,
    children,
}) => {
    const [changeNavBackground, setChangeNavBackground] = useState(true)
    const [scrolling, setScrolling] = useState<boolean>(false)

    const scroll = (e: any) => {
        setScrolling(!!(e.target.documentElement.scrollTop > 0))
    }

    useEffect(() => {
        window.addEventListener("scroll", scroll)
        return () => window.removeEventListener("scroll", scroll)
    }, [])

    const onScroll = () => {
        const mainElem: HTMLElement = document.querySelector("#experience")
        const elemRect = mainElem && mainElem.getBoundingClientRect()
        if (elemRect) {
            return setChangeNavBackground(elemRect.top >= 0)
        }
    }
    useEffect(() => {
        window.addEventListener("scroll", onScroll)
        return () => window.removeEventListener("scroll", onScroll)
    }, [])

    handleGit()

    const { pathname } = useLocation();

    // const popupArr = ["/amenities", "/shops", "/activities", "/restaurants", "/wellness", "/pool", "/location"];

    return (
        <>
            {/* {popupArr.includes(pathname) && <PopupImage />} */}
            <PopupMessage previewData={popupPreviewData} />
            <Container>
                <Header
                    changebg={changeNavBackground}
                    scrolling={scrolling}
                    previewData={navigationPreviewData}
                />
                <FadeInWrapper duration={0.3}>
                    {heroData ? (
                        <Hero {...heroData} toggleBackground={setChangeNavBackground} />
                    ) : null}
                    <Main>{children}</Main>
                    {stayHereData ? (
                        <StayHere {...stayHereData.primary} items={stayHereData.items} />
                    ) : null}
                    {tellUsData ? <TellUs {...tellUsData} /> : null}
                    {bookBannerData ? <BookNowBanner {...bookBannerData} /> : null}
                </FadeInWrapper>
                <Footer previewData={footerPreviewData} />
            </Container>
        </>
    )
}

export default Layout
const Container = styled.div``
