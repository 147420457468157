import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import format from "date-fns/format"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"

import line from "../assets/icons/line.png"
import { Span } from "./atoms/text"
import ClientLink from "./atoms/clientLink"

import { useFooterData } from "../hooks/useFooterData"
import MobileTimeAndTemp from "./molecules/mobileTimeAndTemp"

const Footer = ({ previewData }) => {
  const { data } = previewData ? previewData : useFooterData()
  const [weatherData, setWeatherData] = useState(undefined)

  useEffect(() => {
    getWeather()
      .then(data => setWeatherData(data))
      .catch(error => {
        console.error(error.message)
        setWeatherData(undefined)
      })
  }, [])

  const getWeather = async () => {
    return await fetch(
      `https://api.openweathermap.org/data/2.5/weather?q=Aruba&units=metric&appid=${process.env.GATSBY_WEATHER_API_KEY}`
    ).then(async res => await res.json())
  }

  const linkGroups = [
    data.column_one,
    data.column_two,
    data.column_three,
    data.column_four,
  ]

  const localTime = format(new Date(), "h.mm aaa")

  const { pathname } = useLocation()

  return (
    <Wrapper>
      <Container>
        <LeftSection>
          <Title
            dangerouslySetInnerHTML={{ __html: data.left_section_title.html }}
          />
          <LinkColumns>
            {linkGroups.map((group, i) => {
              return (
                <LinkGroupsContainer key={i}>
                  {group.map(({ label, page }, i) => {
                    if (page.link_type === "Document") {
                      return (
                        <StyledLink
                          key={i}
                          to={`${page.uid === "home" ? "/" : `/${page.uid}`}`}
                        >
                          <Span active={pathname === page.url ? 1 : 0}>
                            {label}
                          </Span>
                        </StyledLink>
                      )
                    }

                    return (
                      <ExternalLink
                        key={i}
                        href={page.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <Span>{label}</Span>
                      </ExternalLink>
                    )
                  })}
                </LinkGroupsContainer>
              )
            })}
          </LinkColumns>
        </LeftSection>
        <MobileTimeAndTemp
          temp={(weatherData && weatherData.main) ? weatherData.main.temp.toString().slice(0, 2) : ""}
          time={localTime.toLowerCase()}
        />
        <MiddleSection>
          <Title
            dangerouslySetInnerHTML={{
              __html: data.middle_section_title.html,
            }}
          />
          <Group>
            {data.contact_column.map(({ information }, i) => (
              <Text key={i}>
                {i !== data.contact_column.length - 1 ? (
                  information
                ) : (
                  <PhoneLinkContainer>
                    <ClientLink type={"phone"} value={information} />
                  </PhoneLinkContainer>
                )}
              </Text>
            ))}
          </Group>
        </MiddleSection>
        <RightSection>
          <TimeAndTempContainer>
            <Text>{localTime.toLowerCase()}</Text>
            <Line src={line} alt="line" />
            {(weatherData && weatherData.main) ? (
              <Text>
                {weatherData.main.temp.toString().slice(0, 2)} C&#176;
              </Text>
            ) : null}
          </TimeAndTempContainer>
          <Title
            dangerouslySetInnerHTML={{
              __html: data.right_section_title.html,
            }}
          />
          <PartnersContainer>
            {data.partners.map(({ image }, i) => (
              <PartnerImage
                key={i}
                // @ts-ignore
                fixed={image.fixed}
                alt={image.alt || "partner"}
                imgStyle={{ objectFit: "contain" }}
              />
            ))}
          </PartnersContainer>
        </RightSection>
        <SocialsContainer>
          {data.socials.map(({ image, link }) => (
            <Anchor
              key={image.alt}
              href={link}
              target="_blank"
              rel="noreferrer noopener"
            >
              <ImageContainer>
                <Img fixed={image.fixed} alt={image.alt || ""} />
              </ImageContainer>
            </Anchor>
          ))}
        </SocialsContainer>
      </Container>
    </Wrapper>
  )
}

export default Footer

const PhoneLinkContainer = styled.span`
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`

const Wrapper = styled.div`
  background: #e5e2df;
  position: relative;
  z-index: 0;
`

const Container = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 80px 0 120px 0;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-areas: "left middle right" "socials . .";
  grid-gap: 40px 0;
  @media (max-width: 850px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "left left" "middle right" "socials socials";
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-areas: "left" "timeTemp" "middle" "right" "socials";
    justify-content: center;
    justify-items: center;
    text-align: center;
  }
`

const LeftSection = styled.div`
  grid-area: left;
`
const MiddleSection = styled.div`
  grid-area: middle;
  border-left: 1px solid var(--primary-text);
  border-right: 1px solid var(--primary-text);
  padding: 0 40px;
  @media (max-width: 1080px) {
    padding: 0 16px;
  }
  @media (max-width: 850px) {
    padding: 0;
    border: none;
  }
`
const RightSection = styled.div`
  grid-area: right;
  padding: 0 0 0 40px;
  @media (max-width: 1080px) {
    padding: 0 0 0 16px;
  }
`
const Title = styled.div`
  p {
    color: var(--primary-text);
    font-size: 1.6rem;
    font-family: "Canela Light Italic", sans-serif;
    padding-bottom: 16px;
  }
`
const Text = styled.p`
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  padding: 8px 0;
`
const LinkColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(1, auto);
  grid-gap: 8px;
  justify-content: center;
  > div a {
    margin: 0 auto;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media(min-width: 501px) {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 16px;
    justify-content: initial;
    > div a {
      margin: initial;
    }
  }
`
const Group = styled.div`
  display: flex;
  flex-direction: column;
  width: max-content;
`
const LinkGroupsContainer = styled.div``

const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: capitalize;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  padding: 8px 0;
  display: block;
  width: max-content;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`
const TimeAndTempContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 24px;
  p {
    font-size: 1.5rem;
  }
  @media (max-width: 600px) {
    display: none;
  }
`

const SocialsContainer = styled.div`
  display: flex;
  grid-area: socials;
  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
  }
`
const Anchor = styled.a`
  padding: 0 16px;
`

const ImageContainer = styled.div`
  .gatsby-image-wrapper {
    max-width: 22px;
    max-height: 22px;
  }
`
const Line = styled.img`
  position: relative;
  top: 4px;
  display: block;
  height: 2px;
  width: 80px;
  padding: 0 8px;
  @media (max-width: 1290px) {
    width: 40px;
  }
`

const PartnersContainer = styled.div`
  max-width: 300px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px 32px;
  @media (max-width: 600px) {
    max-width: unset;
    grid-template-columns: 1fr;
    grid-gap: 32px;
  }
`

const PartnerImage = styled(Img)`
  max-height: 40px;
  max-width: 100px;
`

const ExternalLink = styled.a`
  text-decoration: none;
  text-transform: capitalize;
  color: var(--primary-text);
  font-family: "Moderat Regular", sans-serif;
  padding: 8px 0;
  display: block;
  width: max-content;
`
