import React from "react"
import styled from "styled-components"

/**
 * Button for mobile menu
 * @param setIsOpen
 * @param isOpen
 * @param href
 * @param text
 * @constructor
 */
const MobileMenuButton = ({ setIsOpen, isOpen, href, text }) => {
  return (
    <Button
      href={href}
      target={text === 'Book Now' ? '' : '_blank'}
      rel="noreferrer noopener"
      onClick={() => setIsOpen(!isOpen)}
    >
      {text}
    </Button>
  )
}

export default MobileMenuButton

const Button = styled.a<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  background: white;
  color: var(--primary-text);
  border: none;
  font-family: "Moderat Regular", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  height: 41px;
  cursor: pointer;
  width: 100%;
  text-decoration: none;
  transition: all 0.5s ease-in;
`
